import {envVars} from '@/envVars'
import {StyledFooter, StyledLinkWrap, StyledSignUpLink} from './style'

export const FooterSection = () => {
    const sunproSiteUrl = envVars.VITE_ARYA_GROUP_URL ?? ''

    return (
        <StyledFooter justify="center">
            <StyledLinkWrap>
                Arya Group S.p.A - Website:
                <StyledSignUpLink target="_blank" to={sunproSiteUrl}>
                    aryagroupspa.com
                </StyledSignUpLink>
            </StyledLinkWrap>
        </StyledFooter>
    )
}

FooterSection.displayName = 'FooterSection'
