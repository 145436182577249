export const AUTH_MUTATION_KEYS = {
    login: 'login',
    createInstallerProfile: 'create-installer-profile',
    createResellerProfile: 'create-reseller-profile',
    userUpdate: 'user-update'
}

export const AUTH_QUERY_KEYS = {
    profile: 'profile'
}
