import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledFlexContainer = styled(Flexbox)`
    ${() => css`
        height: 100%;
    `}
`
export const StyledTextContainer = styled.div`
    ${({theme: {typography, palette}}) => css`
        max-width: 550px;
        text-align: center;

        & > h4 {
            font-weight: 500;
            ${typography.displayXl}
            color: ${palette.primary[25]};
        }

        & p {
            color: ${palette.primary[300]};
            ${typography.textMd}
        }

        & > button {
            ${typography.textMd}
        }
    `}
`
export const StyledEmail = styled.span`
    ${({theme: {palette}}) => css`
        color: ${palette.primary[300]};
        cursor: pointer;
        text-decoration: underline;
    `}
`
