import {ArrowLeftIcon, Email01Icon, SlashCircleIcon} from '@/assets/icons/icons'
import {Container} from '@/components/ui/container/Container'
import {useTranslation} from 'react-i18next'
import {StyledEmail, StyledFlexContainer, StyledTextContainer} from './style'
import {Button} from '@/components/ui/button/Button'
import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import {ROUTE_LOGIN} from '@utilities/constants'
import {StyledGoToLoginContainer} from '@/features/password-recovery/components/reset-password/style.ts'
import {useNavigate} from 'react-router-dom'
import useCopyToClipboard from '@/hooks/useCopyToClipboard'
import {toast} from 'react-hot-toast'

export const AccessDenied = () => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {copyToClipboard} = useCopyToClipboard(() => {
        toast.success(t('commons:copied'))
    })

    return (
        <Container fullWidth fullHeight>
            <StyledFlexContainer direction="column" justify="center" align="center" gap={8}>
                <SlashCircleIcon />
                <StyledTextContainer>
                    <h4>{t('access_denied:title')}</h4>
                    <Flexbox direction="column" gap={3} align="center">
                        <p>{t('access_denied:subtitle')}</p>
                        <p>{t('access_denied:subtitle_2')}</p>
                    </Flexbox>
                </StyledTextContainer>
                <Flexbox direction="column" align="center" gap={4}>
                    <Button
                        variant="primary"
                        type="button"
                        onClick={() => window.open('mailto:arya@aryagroupspa.com', '_blank')}
                    >
                        {t('access_denied:cta')}
                        <Email01Icon />
                    </Button>
                    <p>
                        {t('access_denied:copy_email')}{' '}
                        <StyledEmail
                            style={{cursor: 'pointer'}}
                            onClick={() => copyToClipboard('arya@aryagroupspa.com')}
                        >
                            arya@aryagroupspa.com
                        </StyledEmail>
                    </p>
                </Flexbox>
                <StyledGoToLoginContainer justify={'center'} gap={1} onClick={() => navigate(ROUTE_LOGIN)}>
                    <ArrowLeftIcon width={20} height={20} />
                    <p>{t('recovery_password:form:link')}</p>
                </StyledGoToLoginContainer>
            </StyledFlexContainer>
        </Container>
    )
}

AccessDenied.displayName = 'AccessDenied'
