import styled, {css} from 'styled-components'

export const StyledRoot = styled.div<{$vh?: number}>`
    ${({theme: {palette, spacing, sideBarWidth}, $vh}) => css`
        width: ${sideBarWidth}px;
        height: ${$vh}px;
        background-color: ${palette.primary[950]};
        border-right: 1px solid ${palette.primary[800]};
        padding: ${spacing * 8}px;
    `}
`
export const StyledMainWrapper = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: ${spacing}px;
        height: 100%;
        padding: ${spacing * 4}px ${spacing * 4}px 0px;
    `}
`

export const StyledLogo = styled.div`
    ${({theme: {spacing}}) => css`
        display: flex;
        justify-content: center;

        & img {
            padding-bottom: ${spacing * 6}px;
            width: 110px;
            cursor: pointer;
        }
    `}
`

export const StyledList = styled.div`
    ${({theme: {spacing}}) => css`
        display: grid;
        gap: ${spacing / 2}px;
    `}
`

export const StyledUserInfo = styled.div`
    ${({theme: {spacing, palette, typography, truncateText}}) => css`
        border-top: 1px solid ${palette.primary[800]};
        color: ${palette.primary[200]};
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: ${spacing * 3}px;
        margin-top: ${spacing * 6}px;
        padding: ${spacing * 6}px ${spacing * 2}px 0px 0px;
        ${typography.textSm}

        & div {
            ${truncateText};
            max-width: 140px;
            cursor: pointer;
        }
        & svg {
            cursor: pointer;
            fill: ${palette.primary[950]};
        }
        & p {
            font-weight: 600;
            ${truncateText};
            max-width: 154px;
        }
    `}
`
