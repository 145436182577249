import {z} from 'zod'
import {PlaceTypesE, ProjectStatusesE, ProjectTypologyE} from '../types'
import {PHONE_REGEX} from '@/utilities/constants/regex'
import {PageSchema} from '@/utilities/common.schema'
import {UserSchema} from '@/features/auth/services/auth.schema'
import {EMAIL_MAX_CHARS, INPUT_TEXT_MAX_CHARS} from '@/utilities/constants/input'
import {UserCompanySchema} from '@/features/company/services/company.schema'
import {ConsumptionInputTypesE, InputTimeRangeE} from '@/features/project-details/types'
import {ProjectSystemsSchema} from '@/features/project-details/services/project.schema'

const PlacesE = z.nativeEnum(PlaceTypesE)
const TimeRange = z.nativeEnum(InputTimeRangeE)
const InputType = z.nativeEnum(ConsumptionInputTypesE)

export const ElectricityConsumptionSchema = z.object({
    id: z.string(),
    projectId: z.string(),
    type: InputType,
    timeRange: TimeRange,
    value: z.number(),
    commercialSiteValue: z.number().nullable(),
    annualConsumption: z.string().nullable(),
    createdAt: z.string().datetime(),
    updatedAt: z.string().datetime()
})

export const ProjectSchema = z.object({
    id: z.string(),
    companyId: z.string(),
    userId: z.string(),
    status: z.nativeEnum(ProjectStatusesE),
    firstName: z.string(),
    lastName: z.string(),
    phoneNumber: z.string(),
    email: z.string().email(),
    vatNumber: z.string().nullable().optional(),
    address: z.string(),
    latitude: z.string(),
    longitude: z.string(),
    type: z.string(),
    siteType: z.nativeEnum(PlaceTypesE),
    createdAt: z.string(),
    updatedAt: z.string(),
    user: UserSchema.nullable(),
    electricityConsumptions: ElectricityConsumptionSchema,
    projectSystems: z.array(ProjectSystemsSchema),
    shareUrl: z.string().optional(), //TODO: ask BE add it
    clientNickname: z.string().optional(), //TODO: ask BE add it
    totalAmount: z.number().optional(), //TODO: ask BE add it
    typology: z.nativeEnum(ProjectTypologyE).optional(), //TODO: ask BE add it
    ProjectName: z.string().optional(), //TODO: ask BE add it
    company: UserCompanySchema, //TODO: ask BE add it for reseller
    confirmedProjectSystemId: z.string().optional(),
    confirmedProjectSystem: ProjectSystemsSchema,
    quote: z
        .object({
            quoteNumber: z.string()
        })
        .nullish(),
    confirmedQuote: z
        .object({
            id: z.string()
        })
        .nullish(),
    projectNumber: z.string().optional()
})

export const enum CREATE_PROJECT_MODEL {
    FirstName = 'firstName',
    LastName = 'lastName',
    PhoneNumber = 'phoneNumber',
    Email = 'email',
    ClientVAT = 'vatNumber',
    Latitude = 'latitude',
    Longitude = 'longitude',
    Address = 'address',
    SiteType = 'siteType',
    ProjectName = 'projectName',
    CompanyId = 'companyId'
}
export const CreateProjectFormStepSchema = z.object({
    [CREATE_PROJECT_MODEL.FirstName]: z
        .string()
        .min(1, {message: 'errors:name_required'})
        .max(INPUT_TEXT_MAX_CHARS, {message: `errors:text_too_long`}),
    [CREATE_PROJECT_MODEL.LastName]: z
        .string()
        .min(1, {message: 'errors:surname_required'})
        .max(INPUT_TEXT_MAX_CHARS, {message: `errors:text_too_long`}),
    [CREATE_PROJECT_MODEL.PhoneNumber]: z
        .string()
        .min(1, {message: 'errors:phone_required'})
        .min(10, {message: 'errors:phone_too_short'})
        .max(15, {message: 'errors:phone_too_long'})
        .regex(PHONE_REGEX, {message: 'errors:invalid_phone_number'}),
    [CREATE_PROJECT_MODEL.Email]: z
        .string()
        .min(1, {message: 'errors:email'})
        .email({message: 'errors:email_valid'})
        .max(EMAIL_MAX_CHARS, {message: 'errors:email_too_long'}),
    [CREATE_PROJECT_MODEL.ClientVAT]: z.string().optional(),
    [CREATE_PROJECT_MODEL.CompanyId]: z.string().optional(),
    [CREATE_PROJECT_MODEL.Address]: z.string().optional(),
    [CREATE_PROJECT_MODEL.SiteType]: PlacesE.optional()
})

export const CreateProjectPayloadSchema = CreateProjectFormStepSchema.extend({
    [CREATE_PROJECT_MODEL.Latitude]: z.number(),
    [CREATE_PROJECT_MODEL.Longitude]: z.number(),
    [CREATE_PROJECT_MODEL.CompanyId]: z.string()
})

export const ProjectsResponseSchema = PageSchema(ProjectSchema)

export const SingleTotalItem = z.object({
    status: z.nativeEnum(ProjectStatusesE),
    total: z.number()
})
export const ProjectAnalyticsResponseSchema = z.object({
    totals: z.array(SingleTotalItem),
    totalAmount: z.number()
})
