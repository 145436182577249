export const EuroIcon = () => {
    return (
        <svg width="61" height="61" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_5531_57710)">
                <rect x="2.6665" width="56" height="56" rx="11.2" fill="url(#paint0_linear_5531_57710)" />
                <rect x="3.2265" y="0.56" width="54.88" height="54.88" rx="10.64" stroke="#6CB691" strokeWidth="1.12" />
                <path
                    fill="white"
                    d="M35.5 22H26.87C26.24 22 25.65 22.29 25.27 22.8C24.89 23.31 24.75 23.93 24.88 24.52L25.05 25.23H30.5C31.1 25.23 31.6 25.73 31.6 26.33C31.6 26.93 31.1 27.43 30.5 27.43H25.3L25.58 28.71H30.5C31.1 28.71 31.6 29.21 31.6 29.81C31.6 30.41 31.1 30.91 30.5 30.91H25.99L25.74 31.97C25.61 32.56 25.75 33.18 26.13 33.69C26.51 34.2 27.1 34.49 27.73 34.49H35.5C36.1 34.49 36.6 34.99 36.6 35.59C36.6 36.19 36.1 36.69 35.5 36.69H27.73C26.5 36.69 25.36 36.16 24.55 35.27C23.74 34.38 23.34 33.18 23.53 31.99L23.74 30.91H21.49C20.89 30.91 20.39 30.41 20.39 29.81C20.39 29.21 20.89 28.71 21.49 28.71H23.97L23.69 27.43H21.49C20.89 27.43 20.39 26.93 20.39 26.33C20.39 25.73 20.89 25.23 21.49 25.23H23.48L23.29 24.42C23.01 23.23 23.41 22.03 24.22 21.14C25.03 20.25 26.17 19.72 27.4 19.72H35.5C36.1 19.72 36.6 20.22 36.6 20.82C36.6 21.42 36.1 22 35.5 22Z"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_5531_57710"
                    x="0.426504"
                    y="0"
                    width="60.48"
                    height="60.48"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="2.24" />
                    <feGaussianBlur stdDeviation="1.12" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5531_57710" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_5531_57710" result="shape" />
                </filter>
                <linearGradient
                    id="paint0_linear_5531_57710"
                    x1="2.66563"
                    y1="55.9997"
                    x2="58.6658"
                    y2="-0.000681827"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#1E2322" />
                    <stop offset="1" stopColor="#5F937D" />
                </linearGradient>
            </defs>
        </svg>
    )
}
