import {PageSchema} from '@/utilities/common.schema'
import {z} from 'zod'

export enum ComponentCategoriesE {
    Adapters = 'adapters',
    Ballast = 'ballast',
    Battery = 'battery',
    Cables = 'cables',
    CentralEndClamp = 'central-clamp',
    WallBattery = 'charging-station',
    CombinerBox = 'combiner-box',
    Connectors = 'connectors',
    EndClamp = 'end-clamp',
    FasteningKit = 'fastening-kit',
    Fixing = 'fixing',
    Furniture = 'furniture',
    Inverter = 'inverter',
    Kit = 'kit',
    Mat = 'mat',
    Monitoring = 'monitoring',
    Optimizer = 'optimizer',
    Other = 'other',
    Packaging = 'packaging',
    Panel = 'panel',
    PowerAndElectricalSupplies = 'power-and-electrical-supplies',
    PowerDistributionPanelsAC = 'power-distribution-panels-ac',
    PowerDistributionPanelsACDC = 'power-distribution-panels-acdc',
    PowerDistributionPanelsDC = 'power-distribution-panels-dc',
    PowerDistributionPanelsOther = 'power-distribution-panels-other',
    Rail = 'rail',
    RailClamp = 'rail-clamp',
    ScrewAndBracket = 'screw-and-bracket',
    Splice = 'splice',
    Storage = 'storage',
    System = 'system',
    SystemKit = 'system-kit',
    Triangles = 'triangles'
}

export enum AttributeKeysE {
    Structure = 'structure',
    Connection = 'connection',
    Operation = 'operation',
    Power = 'power',
    Section = 'section',
    Length = 'length',
    Width = 'width',
    Type = 'type',
    Code = 'code'
}

export enum AttributeOperatorsE {
    LessThan = '<',
    GreaterThan = '>',
    Equals = '=',
    LessThanOrEqual = '<=',
    GreaterThanOrEqual = '>='
}

const AttributeKeysSchema = z.nativeEnum(AttributeKeysE)
const AttributeOperatorsSchema = z.nativeEnum(AttributeOperatorsE)
const ComponentCategoriesSchema = z.nativeEnum(ComponentCategoriesE)

const ParentComponentCategorySchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string(),
    name: z.string(),
    slug: ComponentCategoriesSchema,
    description: z.string().nullable(),
    parentId: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string()
})

export const ComponentCategorySchema = z.object({
    id: z.string(),
    name: z.string(),
    slug: ComponentCategoriesSchema,
    count: z.number().optional(),
    description: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    parentCategory: ParentComponentCategorySchema.nullable(),
    projectsCount: z.number().optional(), //TODO ask BE to add
    discount: z
        .object({
            id: z.string(),
            createdByCompanyId: z.string(),
            productId: z.string().nullable(),
            categoryId: z.string().nullable(),
            targetCompanyId: z.string().nullable(),
            sourceCompanyId: z.string().nullable(),
            discountRule: z
                .object({
                    name: z.string(),
                    id: z.string()
                })
                .nullish()
        })
        .nullish()
})

export const ComponentAttributeSchema = z.object({
    key: AttributeKeysSchema,
    operator: AttributeOperatorsSchema,
    value: z.union([z.string(), z.number()]).optional()
})

const PivotSchema = z.object({
    pivot_product_id: z.string(),
    pivot_attribute_id: z.string(),
    pivot_value_string: z.string().nullable(),
    pivot_value_number: z.string().nullable()
})

const AttributeSchema = z.object({
    deletedAt: z.string().nullable(),
    id: z.string(),
    name: z.string(),
    slug: AttributeKeysSchema,
    createdAt: z.string(),
    updatedAt: z.string(),
    pivot: PivotSchema
})

export const ComponentSchema = z.object({
    id: z.string(),
    code: z.string(),
    name: z.string(),
    slug: z.string(),
    productId: z.string(),
    categoryId: z.string(),
    priceList: z.number(),
    price: z.string(),
    finalPrice: z.string(),
    available: z.number(),
    createdAt: z.string(),
    updatedAt: z.string(),
    attributes: z.array(AttributeSchema),
    logoImagePath: z.string().optional(),
    search: z.string().optional(),
    discount: z
        .object({
            id: z.string(),
            productId: z.string().nullable(),
            createdByCompanyId: z.string(),
            targetCompanyId: z.string().nullable(),
            discountRule: z
                .object({
                    name: z.string(),
                    id: z.string()
                })
                .optional()
        })
        .optional()
})

export const ComponentListResponseSchema = PageSchema(ComponentSchema)
