import styled, {css} from 'styled-components'
import {Flexbox} from '@components/ui/flexbox/FlexBox.tsx'
import {Link} from 'react-router-dom'

export const StyledFooter = styled(Flexbox)(
    ({theme: {spacing}}) => css`
        width: 100%;
        padding-bottom: ${spacing * 10}px;
    `
)

export const StyledLinkWrap = styled(Flexbox)`
    ${({theme: {palette, typography}}) => css`
        color: ${palette.primary['600']};
        ${typography.textMd};
        font-weight: 500;
    `}
`
export const StyledSignUpLink = styled(Link)`
    ${({theme: {palette, spacing}}) => css`
        cursor: pointer;
        text-decoration: underline;
        padding-left: ${spacing}px;

        &:hover {
            color: ${palette.primary['500']};
        }
    `}
`
