import styled, {css} from 'styled-components'

export const StyledTextContainer = styled.div`
    ${({theme: {typography, palette}}) => css`
        max-width: 620px;
        text-align: center;

        & > h4 {
            font-weight: 500;
            ${typography.displayXl}
            color: ${palette.primary[25]};
        }

        & p {
            color: ${palette.primary[300]};
            ${typography.textMd}
        }

        & > button {
            ${typography.textMd}
        }
    `}
`
