import {Flexbox} from '@/components/ui/flexbox/FlexBox'
import styled, {css} from 'styled-components'

export const StyledResetPasswordWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
`
export const StyledHeaderContainer = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        text-align: center;
        & h4 {
            ${typography.displayXs};
            font-weight: 600;
            color: ${palette.primary['25']};
            width: 100%;
        }
        & p {
            ${typography.textMd};
            color: ${palette.primary['300']};
        }
    `}
`
export const StyledResetPasswordBox = styled.form`
    ${({theme: {spacing, mediaQueries}}) => css`
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 360px;
        gap: ${spacing * 8}px;

        & img {
            margin: 0 auto ${spacing * 6}px;
        }
        ${mediaQueries.m} {
            width: 100%;
        }
    `}
`
export const StyledGoToLoginContainer = styled(Flexbox)`
    ${({theme: {typography, palette}}) => css`
        cursor: pointer;
        text-align: center;
        & p {
            ${typography.textSm};
            font-weight: 600;
            color: ${palette.neutral['300']};
        }
    `}
`

export const StyledIcon = styled.div`
    ${({theme: {palette, spacing}}) => css`
        background-color: ${palette.primary[950]};
        width: 56px;
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 12px;
        margin-bottom: ${spacing * 3}px;
    `}
`
