export const ROUTE_LOGIN = '/login'
export const ROUTE_PASSWORD_RECOVERY = '/password-recovery'
export const ROUTE_RESET_PASSWORD = '/password-recovery/:token'
export const ROUTE_HOME = '/'
export const ROUTE_PROJECTS = '/projects'
export const ROUTE_PROJECT_DETAILS = '/projects/:projectId'
export const ROUTE_INSTALLERS = '/installers'
export const ROUTE_INSTALLER_DETAILS = '/installers/:installerId'
export const ROUTE_RESELLERS = '/resellers'
export const ROUTE_RESELLER_DETAILS = '/resellers/:resellerId'
export const ROUTE_RESELLER_PRODUCTS = '/resellers/:resellerId/products'
export const ROUTE_RESELLER_INVITATION = '/reseller-invitation/:token'
export const ROUTE_INSTALLER_INVITATION = '/installer-invitation/:token'

export const ROUTE_INSTALLER_PRODUCTS = '/installer-products'
export const ROUTE_INSTALLER_DISCOUNTS = '/installer-discounts'
export const ROUTE_LIST = '/list'
export const ROUTE_INSTALLER_LIST = '/installer/:installerId/products'

export const ROUTE_PRODUCTS = '/products'
export const ROUTE_DISCOUNTS = '/discounts'
export const ROUTE_SYSTEM_TEMPLATE = '/system-templates/:projectId'

export const ROUTE_SYSTEM_CONFIGURATOR = '/projects/:projectId/systems/:projectSystemId'
export const ROUTE_CERBERO_CONFIG = '/projects/:projectId/systems-cerbero/:projectSystemId'

export const ROUTE_QUOTE = '/quote/:quoteId'
export const ROUTE_ACCESS_DENIED = '/access-denied'
export const ROUTE_INACTIVE_RESELLER = '/inactive-resellers'
